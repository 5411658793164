<template>
  <div class="main" :style="{'min-height':clientHeight+'px'}">
    <div class="container">
      <img src="@/assets/images/none@2x.png">
      <p class="title">{{title}}</p>
      <p class="describe">{{describe}}</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return{
      clientHeight: document.documentElement.clientHeight,
      title: '活动尚未开始',
      describe: '活动还未开始哦，请晚些再来~'
    }
  },
  created() {
    let msg = this.$route.query.msg
    if(msg === '抱歉，该活动暂未开始。') {
      this.title = '活动尚未开始';
      this.describe = '活动还未开始哦，请晚些再来~'
      document.title = '活动未开始'
    }else if(msg === '抱歉，该活动已结束'){
      this.title = '活动已结束';
      this.describe = '抱歉，您来晚了，活动已经结束了哦～'
      document.title = '活动已结束'
    }else{
      this.title = msg
      this.describe = ''
    }
  }
}
</script>
<style scoped>
.main{
  overflow: hidden;
  background-color: #F5F6F9;
}
.container{
  margin-top: 25%;
  text-align: center;
}
img{
  width: 54%;
  height: 130px;
}
.title{
  color: #999999;
  font-size: 14px;
  line-height: 33px;
  font-weight: 500;
}
.describe{
  color: #999999;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
}
</style>